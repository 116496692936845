import { PencilIcon } from "@heroicons/react/24/solid";
import Button from "../../components/Button";
import { useState } from "react";
import EditCompany from "./EditCompany";

export default function CompanyInfoDisplay(props: { company: Company }) {
  const { company } = props;
  const [editable, setEditable] = useState<boolean>(false);

  if (editable) {
    return <EditCompany company={company} setEditable={setEditable} />;
  }
  return (
    <div>
      <dl className="divide-y divide-gray-100">
      <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
          <dt className="text-sm font-medium leading-6 text-gray-900">
            <div>ID</div>
          </dt>
          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{company.company_id}</dd>
        </div>
        <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
          <dt className="text-sm font-medium leading-6 text-gray-900">
            <div>Internal Name</div>
            <div className="text-gray-500 text-xs">Not shown to user</div>
          </dt>
          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{company.internal_name}</dd>
        </div>
        <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
          <dt className="text-sm font-medium leading-6 text-gray-900">
            <div>Company Type</div>
            <div className="text-gray-500 text-xs">Used to display to the user</div>
          </dt>
          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{company.company_type_name}</dd>
        </div>
        <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
          <dt className="text-sm font-medium leading-6 text-gray-900">
            <div>Industry</div>
            <div className="text-gray-500 text-xs">Determines cutoff scores and Strata job titles</div>
          </dt>
          <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{company.industry_name}</dd>
        </div>
        <div className="px-4 py-3 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
          <dt className="text-sm font-medium leading-6 text-gray-900">
            <div>Address</div>
            <div className="text-gray-500 text-xs">Location of the company</div>
          </dt>
          <div>
            {company.company_address_1 && (
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {company.company_address_1} {company.company_address_2}
              </dd>
            )}
            {company.company_city && company.company_state && (
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {company.company_city}, {company.company_state} {company.company_zip}
              </dd>
            )}
          </div>
        </div>
        <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
          <dt className="text-sm font-medium leading-6 text-gray-900">
            <div>Has Assessments</div>
            <div className="text-gray-500 text-xs">Determines whether a company runs monthly assessments iteself, or is just used to view other companies (parent relationship)</div>
          </dt>
          <dd className={`mt-1 text-sm leading-6 ${company.has_assessments ? "text-green-500" : "text-red-500"} sm:col-span-2 sm:mt-0`}>{company.has_assessments ? "Yes" : "No"}</dd>
        </div>
        <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
          <dt className="text-sm font-medium leading-6 text-gray-900">
            <div>Is Billing</div>
            <div className="text-gray-500 text-xs">Determines whether a company has access to create or modify billing information (for itself or the organization it is a part of)</div>
          </dt>
          <dd className={`mt-1 text-sm leading-6 ${company.is_billing ? "text-green-500" : "text-red-500"} sm:col-span-2 sm:mt-0`}>{company.is_billing ? "Yes" : "No"}</dd>
        </div>
        <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
          <dt className="text-sm font-medium leading-6 text-gray-900">
            <div>Can View Siblings</div>
            <div className="text-gray-500 text-xs">Determines whether a company can see the "Team" tab, which shows a summary of results for its sibling companies</div>
          </dt>
          <dd className={`mt-1 text-sm leading-6 ${company.view_siblings ? "text-green-500" : "text-red-500"} sm:col-span-2 sm:mt-0`}>{company.view_siblings ? "Yes" : "No"}</dd>
        </div>

        {!editable && <Button onClick={() => setEditable(true)} icon={PencilIcon} text="Edit" className="mt-2" />}
      </dl>
    </div>
  );
}
