import { useNavigate } from "react-router";
import { getInitialsFromName } from "../../libs/helpers";

export default function CompanyList(props: { companies: Company[]}) {
    const { companies } = props;
    const navigate = useNavigate();

    return (
        <ul className="divide-y divide-gray-100">
        {companies.sort((a, b) => b.company_name < a.company_name ? 1 : -1).map((company: Company, index: number) => {

          const hasChildren = companies.filter(c => c.parent_company_id === company.company_id).length > 0;
          return (
          <li onClick={() => navigate(`/company/${company.company_id}`)} key={index} className="cursor-pointer flex justify-between items-center gap-x-6 py-2 px-4 hover:bg-gray-50">
            <div className="flex min-w-0 gap-x-4">
              <div className={`h-10 w-10 flex-none rounded-full ${hasChildren ? "bg-green-700" : "bg-blue-700"} text-white flex justify-center items-center`}>{getInitialsFromName(company.company_name)}</div>
              <div className="min-w-0 flex flex-col justify-center">
                <p className="text-sm font-semibold leading-6 text-gray-900">{company.company_name}</p>
                <p className="truncate text-xs leading-5 text-gray-500">{company.internal_name}</p>
              </div>
            </div>
            <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
              <p className="text-sm leading-6 text-gray-900">{company.company_type_name}</p>
              <p className="mt-1 text-xs leading-5 text-gray-500">Created {company.created_at && new Date(company.created_at).toLocaleDateString()}</p>
            </div>
          </li>
        )})}
      </ul>
    )
}